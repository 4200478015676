import { initBlueConic } from "./blueconic";
import { initMedallia } from "./medallia";
import { initOneTrust } from "./onetrust";
import { initWhisbi } from "./whisbi";
import { initGTM } from "./gtm";
import { initCoreWebVitals } from "./core-web-vitals";
import { initCustomPageViewSingleSPA, initCustomPageviewNext } from "./custom-page-view";
import { initOptimizely } from "./optimizely";
import { initGiosg } from "./giosg";

export const init = () => {
  initBlueConic();
  initCoreWebVitals();
  initCustomPageviewNext();
  initGiosg();
  initGTM();
  initMedallia();
  initOneTrust();
  initOptimizely();
  initWhisbi();
};

export const initMyBusinessSingleSPA = () => {
  initCustomPageViewSingleSPA();
  //initOneTrust(); TBD: remove from singlespa index.html
  initGTM();
  initMedallia();
  //initBlueConic(); TBD: add here, remove the b2x-blueconic from singlespa server
};
