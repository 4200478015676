import { init } from "@telia/b2x-cookie-consent";
import {
  ONETRUST_ID_PRODUCTION,
  ONETRUST_ID_TEST,
  ONETRUST_SCRIPT_SRC_PRODUCTION,
  ONETRUST_SCRIPT_SRC_TEST,
} from "../constants";

import { rule } from "@telia/b2x-url-segments";
import { w } from "../util";

export const initOneTrust = () => {
  if (w) {
    const r = rule(new URL(w.location.href));
    const id = r.isProd ? ONETRUST_ID_PRODUCTION : ONETRUST_ID_TEST;
    const src = r.isProd ? ONETRUST_SCRIPT_SRC_PRODUCTION : ONETRUST_SCRIPT_SRC_TEST;

    init(id, src);
  }
};
