import { w } from "../util";
import { onVirtualPageView } from "./virtual-page-view";
import { track, getDefaultEventParameters } from "@telia/b2x-google-analytics";

export const initCustomPageViewSingleSPA = () => {
  onVirtualPageView((newUrl) => {
    track("custom_pageview", {
      page_location: newUrl,
      page_title: document.title,
      ...getDefaultEventParameters(new URL(newUrl)),
    });
  });

  track("custom_pageview", {
    page_location: location.href,
    page_title: document.title,
    ...getDefaultEventParameters(new URL(location.href)),
  });
};

export const initCustomPageviewNext = () => {
  onVirtualPageView((newUrl) => {
    track("custom_pageview", {
      page_location: newUrl,
      page_title: document.title,
      ...getDefaultEventParameters(new URL(newUrl)),
    });
  });

  track("custom_pageview", {
    page_location: w?.location.href,
    page_title: document.title,
    ...getDefaultEventParameters(new URL(location.href)),
  });
};
