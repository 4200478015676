import { onceConsented } from "@telia/b2x-cookie-consent";
import { init } from "@telia/b2x-gtm";
import { GTM_CONTAINER_ID_CLIENT_SIDE_MARKETING, GTM_CONTAINER_ID_TEST } from "../constants";
import { rule } from "@telia/b2x-url-segments";
import { w } from "../util";

export const initGTM = () => {
  onceConsented("C0002", "Google Tag Manager for marketing").then(() => {
    if (w) {
      const r = rule(new URL(w.location.href));

      if (!r.isDev) {
        init(GTM_CONTAINER_ID_TEST);
      }

      if (r.isPublic) {
        init(GTM_CONTAINER_ID_CLIENT_SIDE_MARKETING);
      }
    }
  });
};
