import { onceConsented } from "@telia/b2x-cookie-consent";
import { init, launch } from "@telia/b2x-whisbi";
import { rule } from "@telia/b2x-url-segments";
import { w } from "../util";
import { WHISBI_LAUNCH_FROM_CUSTOMER_REP_CHAT_EVENT_NAME } from "../constants";

const WHISBI_ACCOUNT_ID_B2C = "3ed9da8f-dfe6-4183-92d1-1814bd5bc7dc";
const WHISBI_ACCOUNT_ID_B2B = "c21d70a0-7f09-46b1-a145-19161ee50a4f";

const LOAD_ON_B2B_PATHS = [
  "/foretag/it/acronis-cloud-backup",
  "/foretag/uppkoppling/bredband-plus",
  "/foretag/uppkoppling/bredband-start",
  "/foretag/it/it-support",
  "/foretag/it/it-support-direkt",
  "/foretag/it/cybersakerhet",
  "/foretag/it/hosting",
  "/foretag/it/it-tjanster",
  "/foretag/it/microsoft-365",
  "/foretag/uppkoppling/mobilt-bredband",
  "/foretag/telefoni/smart-connect",
  "/foretag/it/smart-sakerhet",
  "/foretag/it/smart-sakerhet-microsoft-365",
  "/foretag/it/storegate",
  "/foretag/telefoni/foretagsabonnemang",
  "/foretag/telefoni/vaxlar",
  "/foretag/telefoni/touchpoint",
  "/foretag/uppkoppling/bredband",
  "/foretag/it/microsoft-365-halva-priset",
];

export const initWhisbi = () => {
  // Open Whisbi upon Ace AI TV Chat custom event.
  window.addEventListener(WHISBI_LAUNCH_FROM_CUSTOMER_REP_CHAT_EVENT_NAME, () => {
    window.whisbi?.openWidget && window.whisbi.openWidget({ action: "oneToOneWidget" });
  });

  onceConsented("C0001", "Enable customer engagement").then(() => {
    if (w) {
      const r = rule(new URL(w.location.href));
      if (r.pathStartsWith(["/mitt-telia/whisbi-support"])) {
        init(WHISBI_ACCOUNT_ID_B2C, "app");
      } else if (!r.pathStartsWith(["/mitt-telia", "/foretag"])) {
        init(WHISBI_ACCOUNT_ID_B2C);
      } else if (r.pathStartsWith(LOAD_ON_B2B_PATHS)) {
        init(WHISBI_ACCOUNT_ID_B2B);
        // on /foretag we need to force launch
        // while /privat (b2c) BlueConic does this for us.
        launch();
      }
    }
  });
};
