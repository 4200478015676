import { w } from "../util";
import { onVirtualPageView } from "./virtual-page-view";
import { track, getDefaultEventParameters } from "@telia/b2x-google-analytics";

export const initCustomPageViewSingleSPA = () => {
  onVirtualPageView((newUrl) => {
    track("custom_pageview", {
      page_location: newUrl,
      page_title: document.title,
      ...getDefaultEventParameters(new URL(newUrl)),
    });
  });

  track("custom_pageview", {
    page_location: location.href,
    page_title: document.title,
    ...getDefaultEventParameters(new URL(location.href)),
  });
};

// Used for legacy, where next nor singleSPA does not exist
export const trackCustomPageViewLegacy = () => {
  if (typeof w?.next === "undefined") {
    track("custom_pageview", {
      page_location: location.href,
      page_title: document.title,
      ...getDefaultEventParameters(new URL(location.href)),
    });
  }
};

// Used in a hook inside next apps
export const trackCustomPageView = (pathname: string) => {
  if (w) {
    track("custom_pageview", {
      page_location: pathname,
      page_title: document.title,
      ...getDefaultEventParameters(
        new URL(`${location.protocol}//${location.hostname}${pathname}`)
      ),
    });
  }
};
