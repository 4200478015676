import { onceConsented } from "@telia/b2x-cookie-consent";
import { inject } from "@telia/b2x-giosg";
import { rule } from "@telia/b2x-url-segments";
import { w } from "../util";
import { GIOSG_ACCOUNT_ID, GIOSG_TEST_PATH } from "../constants";

export const initGiosg = () => {
  if (w) {
    const r = rule(new URL(w.location.href));

    if (r.pathStartsWith([GIOSG_TEST_PATH])) {
      onceConsented("C0001", "Enable Giosg").then(() => inject(GIOSG_ACCOUNT_ID));
    }
  }
};
