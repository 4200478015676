type VirtualPageViewCallback = (newUrl: string, oldUrl: string) => void;

let listening = false;

export const onVirtualPageView = (callback: VirtualPageViewCallback) => {
  if (listening || typeof window === "undefined") return;
  listening = true;

  // Single Spa
  window.addEventListener("single-spa:routing-event", (event) => {
    const { oldUrl, newUrl } = (event as CustomEvent).detail;
    if (oldUrl !== newUrl) {
      callback(newUrl, oldUrl);
    }
  });

  // Next.js
  if (window.next?.router) {
    let oldUrl = "";
    window.next.router.events.on("beforeHistoryChange", () => {
      oldUrl = window.location.href;
    });

    window.next.router.events.on("routeChangeComplete", () => {
      callback(window.location.href, oldUrl);
    });
  }
};
