export const MEDALLIA_ID = 338444;

export const GTM_CONTAINER_ID_CLIENT_SIDE_MARKETING = "GTM-TVLFQQ";
export const GTM_CONTAINER_ID_TEST = "GTM-5CP4QJR";

export const GIOSG_ACCOUNT_ID = "fe89fb74-09b3-11ee-bd65-0242ac120008";
export const GIOSG_TEST_PATH = "/foretag/artiklar/5G";

export const BLUECONIC_B2C_SRC = "https://t944.telia.se/script.js";
export const BLUECONIC_B2B_SRC = "https://n467.telia.se/script.js";

export const ONETRUST_ID_PRODUCTION = "f88f5b19-b79e-4ecb-bd1d-a6b7b25a41ef";
export const ONETRUST_SCRIPT_SRC_PRODUCTION = `https://cdn.cookielaw.org/consent/${ONETRUST_ID_PRODUCTION}/otSDKStub.js`;
export const ONETRUST_ID_TEST = "f88f5b19-b79e-4ecb-bd1d-a6b7b25a41ef-test";
export const ONETRUST_SCRIPT_SRC_TEST = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";

export const CORE_WEB_VITALS_SAMPLE_RATE = 0.25;
export const CORE_WEB_VITALS_EVENT_NAME = "coreWebVitals";

export const WHISBI_LAUNCH_FROM_CUSTOMER_REP_CHAT_EVENT_NAME = "ace-open-customer-rep-chat";
